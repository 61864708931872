import React, { useEffect, useState } from 'react';
import { useTheme, useMediaQuery, Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Breadcrumbs, Snackbar, Alert } from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import Sidebar from './sidebar';
import Modal from './modal';
import useStyles from './styles';
import makeApiRequest from '../utils/api';
import { getColumns } from './columns';
import DataTable from './commonTable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import './topics.css';

const Topics = () => {

  const topicsColumns = getColumns('topics');

  const topicsActions = [
    { label: 'Edit', icon: <EditIcon />, handler: (topic) => openModal(topic, true) },
    { label: 'Delete', icon: <DeleteIcon />, handler: (topic) => openDeleteConfirm(topic.id) },
  ];

  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { subjectId } = useParams();
  const [topics, setTopics] = useState([]);
  const [filteredTopics, setFilteredTopics] = useState([]);
  const [modalState, setModalState] = useState({ open: false, isEditing: false, topic: null });
  const [deleteConfirm, setDeleteConfirm] = useState({ open: false, topicId: null });
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const location = useLocation();
  const subjectName = location.state?.subjectName || 'Subject Name';

  useEffect(() => {
    fetchTopics();
  }, [subjectId]);

  const fetchTopics = async () => {
    setLoading(true);
    try {
      const data = await makeApiRequest(`api/topics/subject/${subjectId}`);
      if (data && Array.isArray(data)) {
        const sortedTopics = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        console.log(sortedTopics, "sortedTopics");
        setTopics(sortedTopics);
        setFilteredTopics(sortedTopics);
      } else {
        console.error('No topics found for the subject');
      }
    } catch (error) {
      console.error('Error fetching topics:', error);
      setFilteredTopics([]);
    } finally {
      setLoading(false);
    }
  };

  const handleTopicClick = (topic) => navigate(`/articles/${topic.id}`, { state: { topicName: topic.name, subjectName } });

  const handleAddOrEditTopic = async (topicData) => {
    setLoading(true);
    const method = modalState.isEditing ? 'PUT' : 'POST';
    const url = modalState.isEditing ? `api/topics/${modalState.topic.id}` : 'api/topics';

    try {
      await makeApiRequest(url, method, { 'Content-Type': 'application/json' }, { ...topicData, subjectId });
      fetchTopics();
      closeModal();
      setSnackbarMsg(modalState.isEditing ? 'Topic updated successfully!' : 'Topic added successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error(`Error ${modalState.isEditing ? 'editing' : 'adding'} topic:`, error);
      setSnackbarMsg(`Failed to ${modalState.isEditing ? 'edit' : 'add'} topic`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteTopic = async () => {
    setLoading(true);
    try {
      await makeApiRequest(`api/topics/${deleteConfirm.topicId}`, 'DELETE');
      fetchTopics();
      closeDeleteConfirm();
      setSnackbarMsg('Topic deleted successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting topic:', error);
      setSnackbarMsg('Failed to delete topic');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const openModal = (topic = null, isEditing = false) => setModalState({ open: true, isEditing, topic });
  const closeModal = () => setModalState({ open: false, isEditing: false, topic: null });
  const openDeleteConfirm = (topicId) => setDeleteConfirm({ open: true, topicId });
  const closeDeleteConfirm = () => setDeleteConfirm({ open: false, topicId: null });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleBackToSubject = () => {
    navigate(-1);
  }

  const isTextTruncated = (text, maxLength) => text.length > maxLength;

  return (
    <Box className={classes.root} sx={{ display: "flex" }}>
      <Sidebar />
      <Box className={classes.content} sx={{ flexGrow: 1, paddingTop: "16px", paddingLeft:'5px'}}>
        <Breadcrumbs
          separator="/"
          aria-label="breadcrumb"
          sx={{ marginTop: isMobile ? "20px" : "0px", marginBottom: isMobile ? "0px" : "10px" }}
        >
          <Typography
            className='breadcrumbs-title'
            color="textPrimary"
            onClick={() => navigate('/')}
          >
            <HomeIcon className='breadcrumbs-title margin-right-2' fontSize="inherit" />Home
          </Typography>

          <Tooltip title={isTextTruncated(subjectName, 10) ? subjectName : ''} arrow>
            <Typography
              color="textPrimary"
              onClick={handleBackToSubject}
              sx={{ cursor: 'pointer' }}
            >
              Subjects - {isTextTruncated(subjectName, 10) ? `${subjectName.slice(0, 10)}...` : subjectName}
            </Typography>
          </Tooltip>

          <Tooltip title={isTextTruncated("Topics", 10) ? "Topics" : ''} arrow>
            <Typography>Topics</Typography>
          </Tooltip>
        </Breadcrumbs>

        <DataTable
          item="Topic"
          openModal={openModal}
          handleClick={handleTopicClick}
          data={filteredTopics}
          loading={loading}
          columns={topicsColumns}
          actions={topicsActions}
        />
        <Modal
          open={modalState.open}
          onClose={closeModal}
          data={modalState.topic}
          isEditing={modalState.isEditing}
          onSubmit={handleAddOrEditTopic}
          isTopic={true}
        />
        <Dialog open={deleteConfirm.open} onClose={closeDeleteConfirm}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this topic?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDeleteConfirm}>Cancel</Button>
            <Button onClick={handleDeleteTopic} variant="contained">Delete</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMsg}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default Topics;