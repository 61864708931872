import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';
import useStyles from './styles';

const FullDescriptionModal = ({ open, onClose, description }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.DialogTitle}>Description</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            maxHeight: '250px',
            overflowY: 'auto',
            width: '100%',
            padding: '15px',
            borderRadius: '5px',
            border: '1px solid #ddd',
            backgroundColor: '#f5f5f5',
            margin: '5px 0',
          }}>
          <p>{description}</p>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FullDescriptionModal;