import React, { useState } from 'react';

const SortingComponent = ({ data, children }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPageState, setRowsPerPageState] = useState(10);

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPageState(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = orderBy ? data.sort(getComparator(order, orderBy)) : data;

  const paginatedData = sortedData.slice(page * rowsPerPageState, page * rowsPerPageState + rowsPerPageState);

  return (
    <>
      {children({ paginatedData, order, orderBy, handleSortRequest, page, rowsPerPageState, handleChangePage, handleChangeRowsPerPage })}
    </>
  );
};

export default SortingComponent;