// import React, { useState, useRef } from 'react';
// import { Box, Divider, Typography, TextField, IconButton } from '@mui/material';
// import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
// import VideoCallIcon from '@mui/icons-material/VideoCall';
// import md from './markdownRender';
// import './body.css';
// import makeApiRequest from '../utils/api';


// if (md.set) {
//     md.set({
//         html: true,        
//         breaks: true,     
//         linkify: true     
//     });
// }

// const Body = ({ markdownContent, setMarkdownContent }) => {
//     const fileInputRef = useRef(null);
//     const [cursorPosition, setCursorPosition] = useState(0);

//     const handleFileUpload = async (event) => {
//         const file = event.target.files[0];
//         if (file) {
//             const validImageFormats = ['image/jpeg', 'image/png', 'image/jpg'];
//             const validVideoFormats = ['video/mp4', 'video/m4a'];

//             if (!validImageFormats.includes(file.type) && !validVideoFormats.includes(file.type)) {
//                 alert('Invalid file format. Please upload a JPG, PNG, or JPEG image, or MP4, M4A video.');
//                 return;
//             }

//             const formData = new FormData();
//             formData.append('file', file);

//             try {
//                 const uploadResponse = await makeApiRequest('/api/assets', 'POST', {
//                     'Content-Type': 'multipart/form-data',
//                 }, formData);

//                 if (uploadResponse && uploadResponse.url) {
//                     console.log('File uploaded successfully:', uploadResponse);
//                     insertMediaAtCursor(uploadResponse.url, file.type.startsWith('image/'));
//                 } else {
//                     console.error('Failed to upload file');
//                 }
//             } catch (error) {
//                 console.error('Error uploading file:', error);
//             }
//         }
//     };

//     const insertMediaAtCursor = (url, isImage) => {
//         let mediaTag;
//         if (isImage) {
//             mediaTag = `![](${url})`;
//         } else {
           
//             mediaTag = `
// <video controls width="400" style="max-width: 100%;">
//     <source src="${url}" type="video/mp4">
//     <p>Your browser doesn't support HTML5 video. Here's a <a href="${url}">link to the video</a> instead.</p>
// </video>`;
//         }

//         const newContent =
//             markdownContent.slice(0, cursorPosition) +
//             mediaTag +
//             markdownContent.slice(cursorPosition);

//         setMarkdownContent(newContent);
//     };

//     const handleTextFieldChange = (event) => {
//         setMarkdownContent(event.target.value);
//         setCursorPosition(event.target.selectionStart);
//     };

//     const triggerFileUpload = (type) => {
//         fileInputRef.current.accept = type === 'image' ? 'image/*' : 'video/*';
//         fileInputRef.current.click();
//     };


//     const renderContent = (content) => {
//         try {
//             return md.render(content);
//         } catch (error) {
//             console.error('Error rendering markdown:', error);
//             return 'Error rendering content';
//         }
//     };

//     return (
//         <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, height: { xs: 'auto', md: '530px' }, border: '1px solid #e0e0e0', backgroundColor: 'white' }}>
//             <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', p: 2, width: { xs: '100%', md: '50%' } }}>
//                 <Typography variant="h6" className='body-heading'>Content</Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//                     <IconButton onClick={() => triggerFileUpload('image')}>
//                         <AddPhotoAlternateIcon />
//                     </IconButton>
//                     <IconButton onClick={() => triggerFileUpload('video')}>
//                         <VideoCallIcon />
//                     </IconButton>
//                 </Box>
//                 <TextField
//                     multiline
//                     fullWidth
//                     rows={20}
//                     variant="outlined"
//                     value={markdownContent}
//                     onChange={handleTextFieldChange}
//                     onSelect={(e) => setCursorPosition(e.target.selectionStart)}
//                     sx={{
//                         height: '100%',
//                         overflow: 'hidden',
//                         '& .MuiOutlinedInput-root': {
//                             border: 'none',
//                             '& fieldset': { border: 'none' }
//                         },
//                         '& textarea': {
//                             overflow: 'hidden',
//                             resize: 'none'
//                         }
//                     }}
//                 />
//                 <input
//                     type="file"
//                     hidden
//                     ref={fileInputRef}
//                     onChange={handleFileUpload}
//                 />
//             </Box>

//             <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'block' }, borderColor: '#e0e0e0' }} />

//             <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', p: 2, width: { xs: '100%', md: '50%' }, mt: { xs: 2, md: 0 } }}>
//                 <Typography variant="h6" sx={{ backgroundColor: '#D9D9D9', textAlign: 'center', p: 1, mb: 2, fontWeight: 'bold', color: 'black' }}>Preview</Typography>
//                 <Box
//                     sx={{
//                         height: '100%',
//                         overflow: 'auto',
//                         p: 2,
//                         '& video': {
//                             maxWidth: '100%',
//                             display: 'block',
//                             marginBottom: '1rem'
//                         }
//                     }}
//                     dangerouslySetInnerHTML={{ __html: renderContent(markdownContent) }}
//                 />
//             </Box>
//         </Box>
//     );
// };

// export default Body;





import React, { useState, useRef } from 'react';
import { Box, Divider, Typography, TextField, IconButton } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import md from './markdownRender';
import './body.css';


import makeApiRequest from '../utils/api';

if (md.set) {
    md.set({
        html: true,        
        breaks: true,     
        linkify: true     
    });
}

const Body = ({ markdownContent, setMarkdownContent }) => {
    const fileInputRef = useRef(null);
    const [cursorPosition, setCursorPosition] = useState(0);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const validImageFormats = ['image/jpeg', 'image/png', 'image/jpg'];
            const validVideoFormats = ['video/mp4', 'video/m4a'];

            if (!validImageFormats.includes(file.type) && !validVideoFormats.includes(file.type)) {
                alert('Invalid file format. Please upload a JPG, PNG, or JPEG image, or MP4, M4A video.');
                return;
            }

            const formData = new FormData();
            formData.append('file', file);

            try {
                const uploadResponse = await makeApiRequest('/api/assets', 'POST', {
                    'Content-Type': 'multipart/form-data',
                }, formData);

                if (uploadResponse && uploadResponse.url) {
                    console.log('File uploaded successfully:', uploadResponse);
                    insertMediaAtCursor(uploadResponse.url, file.type.startsWith('image/'));
                } else {
                    console.error('Failed to upload file');
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    const insertMediaAtCursor = (url, isImage) => {
        let mediaTag;
        if (isImage) {
            mediaTag = `![](${url})`;
        } else {
            mediaTag = `<video controls width="400" style="max-width: 100%;"><source src="${url}" type="video/mp4">
    <p>Your browser doesn't support HTML5 video. Here's a <a href="${url}">link to the video</a> instead.</p></video>`;}

        const newContent =
            markdownContent.slice(0, cursorPosition) + mediaTag +
            markdownContent.slice(cursorPosition);

        setMarkdownContent(newContent);
    };

    // const handleTextFieldChange = (event) => {
    //     setMarkdownContent(event.target.value);
    //     setCursorPosition(event.target.selectionStart);
    // };

    const handleTextFieldChange = (event) => {
        const newValue = event.target.value;
        setCursorPosition(event.target.selectionStart);
        setMarkdownContent(newValue);
    };


    const triggerFileUpload = (type) => {
        fileInputRef.current.accept = type === 'image' ? 'image/*' : 'video/*';
        fileInputRef.current.click();
    };

    const renderContent = (content) => {
        try {
            const processedContent = content.replace(/\n/g, '<br/>');
            return md.render(processedContent);
        } catch (error) {
            console.error('Error rendering markdown:', error);
            return 'Error rendering content';
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const lines = markdownContent.split('\n');
            const currentLineIndex = event.target.value.split('\n').length - 1;
            const currentLine = lines[currentLineIndex].trim();
    
            const match = currentLine.match(/^(\d+)\.\s*(.*)$/);
            if (match) {
                const nextNumber = parseInt(match[1], 10) + 1;
                const newLine = `${nextNumber}. `;
                lines.splice(currentLineIndex + 1, 0, newLine); 
                setMarkdownContent(lines.join('\n'));
                event.preventDefault();
            }
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, height: { xs: 'auto', md: '530px' }, border: '1px solid #e0e0e0', backgroundColor: 'white' }}>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', p: 2, width: { xs: '100%', md: '50%' } }}>
                <Typography variant="h6" className='body-heading'>Content</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <IconButton onClick={() => triggerFileUpload('image')}>
                        <AddPhotoAlternateIcon />
                    </IconButton>
                    <IconButton onClick={() => triggerFileUpload('video')}>
                        <VideoCallIcon />
                    </IconButton>
                </Box>
                <TextField
                    multiline
                    fullWidth
                    rows={20}
                    variant="outlined"
                    value={markdownContent}
                    onChange={handleTextFieldChange}
                    onSelect={(e) => setCursorPosition(e.target.selectionStart)}
                    onKeyDown={handleKeyDown}
                    sx={{
                        height: '100%',
                        overflow: 'hidden',
                        '& .MuiOutlinedInput-root': {
                            border: 'none',
                            '& fieldset': { border: 'none' }
                        },
                        '& textarea': {
                            overflow: 'hidden',
                            resize: 'none'
                        }
                    }}
                />
                <input
                    type="file"
                    hidden
                    ref={fileInputRef}
                    onChange={handleFileUpload}
                />
            </Box>

            <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'block' }, borderColor: '#e0e0e0' }} />

            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', p: 2, width: { xs: '100%', md: '50%' }, mt: { xs: 2, md: 0 } }}>
                <Typography variant="h6" sx={{ backgroundColor: '#D9D9D9', textAlign: 'center', p: 1, mb: 2, fontWeight: 'bold', color: 'black' }}>Preview</Typography>
                <Box
                    sx={{
                        height: '100%',
                        overflow: 'auto',
                        p: 2,
                        '& video': {
                            maxWidth: '100%',
                            display: 'block',
                            marginBottom: '1rem'
                        }
                    }}
                    dangerouslySetInnerHTML={{ __html: renderContent(markdownContent) }}
                />
            </Box>
        </Box>
    );
};

export default Body;
