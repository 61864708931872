import axios from "axios";


const BASE_URL = process.env.REACT_APP_BASE_URL

const makeApiRequest = async (url, method = 'GET', headers = {}, payload = {}) => {
    const token = sessionStorage.getItem("token")
    const config = {
        method,
        url: `${BASE_URL}${url.startsWith('/') ? url.slice(1) : url}`,  
        headers:{
            ...headers,
            Authorization: `Bearer ${token}` 
        },
        data: payload,
    };

    try {
        const response = await axios(config);
        console.log(response)
        return response.data; 
    } catch (error) {
        const errorMessage = error.response?.data?.error 
        throw errorMessage;
    }
};

export default makeApiRequest;
