import { Remarkable } from 'remarkable';

const md = new Remarkable();


md.renderer.rules.heading_open = (tokens, idx) => {
    switch (tokens[idx].hLevel) {
        case 1:
            return '<Typography variant="h1" component="h1" class="mui-heading mui-heading-1">';
        case 2:
            return '<Typography variant="h2" component="h2" class="mui-heading mui-heading-2">';
        case 3:
            return '<Typography variant="h3" component="h3" class="mui-heading mui-heading-3">';
        case 4:
            return '<Typography variant="h4" component="h4" class="mui-heading mui-heading-4">';
        case 5:
            return '<Typography variant="h5" component="h5" class="mui-heading mui-heading-5">';
        case 6:
            return '<Typography variant="h6" component="h6" class="mui-heading mui-heading-6">';
        default:
            return `<h${tokens[idx].hLevel}>`;
    }
};


md.renderer.rules.paragraph_open = () => {
    return '<Typography paragraph class="mui-paragraph">';
};


md.renderer.rules.list_item_open = () => {
    return '<Typography component="li" class="mui-list-item">';
};


md.renderer.rules.image = (tokens, idx) => {
    const src = tokens[idx].src;
    const alt = tokens[idx].alt || '';
    const title = tokens[idx].title ? ` title="${tokens[idx].title}"` : '';
    return `<img src="${src}" alt="${alt}"${title} class="mui-image">`;
};


const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = `
    .mui-heading-1 {
        font-size: 2.5rem;
        margin: 1rem 0;
    }
    .mui-heading-2 {
        font-size: 2rem;
        margin: 0.75rem 0;
    }
    .mui-heading-3 {
        font-size: 1.75rem;
        margin: 0.5rem 0;
    }
    .mui-heading-4 {
        font-size: 1.5rem;
        margin: 0.5rem 0;
    }
    .mui-heading-5 {
        font-size: 1.25rem;
        margin: 0.5rem 0;
    }
    .mui-heading-6 {
        font-size: 1rem;
        margin: 0.5rem 0;
    }
    .mui-paragraph {
        font-size: 1rem;
        margin: 0.5rem 0;
    }
    .mui-list-item {
        margin: 0.25rem 0;
        list-style-type: disc;
        margin-left: 1.5rem;
    }
    .mui-image {
        max-width: 100%;
        height: auto;
        margin: 1rem 0;
    }
`;
document.head.appendChild(styleSheet);

export default md;