import React, { useState } from 'react';
import { TableCell, TextField, TableSortLabel, Box, IconButton } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import useStyles from './styles';

const FilterComponent = ({ header, order, orderBy, handleSortRequest, onColumnFilter }) => {
    const classes = useStyles();
    const [filterValue, setFilterValue] = useState('');
    const [activeField, setActiveField] = useState(null);
    const [dateRange, setDateRange] = useState({ start: '', end: '' });

    const handleFilterChange = (value) => {
        setFilterValue(value);
        onColumnFilter(header.id, value);
        if (value === '') {
            setActiveField(null);
        }
    };

    const handleDateChange = (type, value) => {
        const updatedRange = { ...dateRange, [type]: value };
        setDateRange(updatedRange);
        if (updatedRange.start === '' && updatedRange.end === '') {
            onColumnFilter(header.id, '');
            setActiveField(null); 
        } else if (updatedRange.start && updatedRange.end) {
            onColumnFilter(header.id, updatedRange);
        }
    };

    const toggleFilter = () => {
        if (activeField === header.id) {
            if (header.type === 'date' && (dateRange.start || dateRange.end)) {
                setDateRange({ start: '', end: '' });
                onColumnFilter(header.id, ''); 
            } else if (filterValue !== '') {
                setFilterValue(''); 
                onColumnFilter(header.id, ''); 
            }
            setActiveField(null); 
        } else {
            setActiveField(header.id); 
        }
    };

    return (
        <TableCell key={header.id}>
            <Box display="flex" alignItems="center">
                <TableSortLabel
                    hideSortIcon={false}
                    active={orderBy === header.id}
                    direction={orderBy === header.id ? order : 'asc'}
                    className={`${orderBy === header.id ? classes.activeSort : classes.defaultSort}`}
                    onClick={() => handleSortRequest(header.id)}
                >
                    {header.label}
                </TableSortLabel>
                <IconButton onClick={toggleFilter} size="small" className={classes.filterIcon}>
                    <FilterListIcon />
                </IconButton>
            </Box>
            {header.type === 'date' && activeField === header.id ? (
                <Box display="flex" alignItems="center">
                    <TextField
                        type="date"
                        variant="outlined"
                        size="small"
                        value={dateRange.start}
                        onChange={(e) => handleDateChange('start', e.target.value)}
                        className={classes.filterInput}
                    />
                    <span className={classes.to}>to</span>
                    <TextField
                        type="date"
                        variant="outlined"
                        size="small"
                        value={dateRange.end}
                        onChange={(e) => handleDateChange('end', e.target.value)}
                        className={classes.filterInput}
                    />
                </Box>
            ) : activeField === header.id ? (
                <Box display="flex" alignItems="center">
                    <TextField
                        variant="outlined"
                        placeholder={`Filter by ${header.label}`}
                        value={filterValue}
                        onChange={(e) => handleFilterChange(e.target.value)}
                        className={classes.filterInput}
                        autoFocus
                    />
                </Box>
            ) : null}
        </TableCell>
    );
};

export default FilterComponent;