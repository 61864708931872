// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useMediaQuery, useTheme, Box, Button, Typography, Breadcrumbs, Snackbar, Alert } from '@mui/material';
// import HomeIcon from '@mui/icons-material/Home';
// import Sidebar from './sidebar';
// import ThreadsTable from './ThreadsTable';
// import ThreadDetailsModal from './ThreadDetailsModal';
// import useStyles from './styles';
// import makeApiRequest from '../utils/api';

// const Threads = () => {
//   const classes = useStyles();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [threads, setThreads] = useState([]);
//   const [localThreads, setLocalThreads] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [selectedThread, setSelectedThread] = useState(null);
//   const [detailsModalOpen, setDetailsModalOpen] = useState(false);
//   const [modalState, setModalState] = useState({
//     open: false,
//     tool: null,
//     isEditing: false
//   });
//   const [snackbarState, setSnackbarState] = useState({ open: false, message: '', severity: 'success' });
//   useEffect(() => {
//     fetchData();
//   }, []);
//   const fetchData = async () => {
//     try {
//       const response = await axios.get('/threads.json');
//       setThreads(response.data.threads.slice(0, 5));
//     } catch (error) {
//       console.error('Error fetching threads:', error);
//       showSnackbar('Failed to fetch threads', 'error');
//     } finally {
//       setLoading(false);
//     }
//   };
//   const getThreadById = async (threadId) => {
//     setLoading(true);
//     try {
//       const localThread = localThreads.find(t => t.id === threadId);
//       if (localThread) {
//         setSelectedThread(localThread);
//       } else {
//         const response = await axios.get(`/threads/${threadId}.json`);
//         setSelectedThread(response.data);
//       }
//       setDetailsModalOpen(true);
//       showSnackbar('Thread details fetched successfully');
//     } catch (error) {
//       console.error('Error fetching thread details:', error);
//       showSnackbar('Failed to fetch thread details', 'error');
//     } finally {
//       setLoading(false);
//     }
//   };
//   const createThread = async(threadData) => {
//     //Make an api call for post request
//     try {
//       const response = await makeApiRequest('');
//       console.log(response)
//     } catch (error) {
//       console.log(error)
//     }
//     //Adding the data for now to the local storage
//     const newThread = {
//       ...threadData,
//       id: Date.now().toString(),
//       createdAt: new Date().toISOString(),
//       createdBy: 'admin@agilophiles.com'
//     };
//     setLocalThreads(prevThreads => [newThread, ...prevThreads]);
//     showSnackbar('Thread created successfully');
//     closeModal();
//   };
//   const updateThreadStatus = async (threadId, newStatus) => {
//     setLoading(true);
//     try {
//       const localThreadIndex = localThreads.findIndex(t => t.id === threadId);
//       if (localThreadIndex !== -1) {
//         const updatedLocalThreads = [...localThreads];
//         updatedLocalThreads[localThreadIndex] = { ...updatedLocalThreads[localThreadIndex], status: newStatus };
//         setLocalThreads(updatedLocalThreads);
//       } else {
//         await axios.put(`/threads/${threadId}.json`, { status: newStatus });
//         const updatedThreads = threads.map(thread =>
//           thread.id === threadId ? { ...thread, status: newStatus } : thread
//         );
//         setThreads(updatedThreads);
//       }
//       showSnackbar('Thread status updated successfully');
//     } catch (error) {
//       console.error('Error updating thread status:', error);
//       showSnackbar('Failed to update thread status', 'error');
//     } finally {
//       setLoading(false);
//     }
//   };
//   const handleAddEditThread = (threadData) => {
//     if (modalState.isEditing) {
//       updateThreadStatus(threadData.id, threadData.status);
//     } else {
//       createThread(threadData);
//     }
//   };
//   const showSnackbar = (message, severity = 'success') => {
//     setSnackbarState({ open: true, message, severity });
//   };
//   const handleReadMore = async (threadId) => {
//     await getThreadById(threadId);
//   };
//   const openModal = (tool = null) => {
//     setModalState({
//       open: true,
//       tool : tool || {status: "open"},
//       isEditing: !!tool
//     });
//   };
//   const closeModal = () => {
//     setModalState({
//       open: false,
//       tool: null,
//       isEditing: false
//     });
//   };
//   const handleEdit = (threadId, currentStatus) => {
//     setModalState({
//       open: true,
//       tool: { id: threadId, status: currentStatus },
//       isEditing: true
//     });
//   };
//   const allThreads = [...localThreads, ...threads];
//   return (
//     <Box className={classes.root} sx={{ display: 'flex' }}>
//       <Sidebar />
//       <Box className={classes.content} sx={{ margin: "20px", flexGrow: 1, padding: '16px' }}>
//         <Breadcrumbs
//           aria-label="breadcrumb"
//           separator="/"
//           sx={{ marginBottom: isMobile ? "0px" : "10px", marginTop: isMobile ? "20px" : "0px" }}
//         >
//           <Typography className="home-icons-container-styles" color="textPrimary">
//             <HomeIcon className='home-icon-styles' fontSize="inherit" />
//             Home
//           </Typography>
//           <Typography>Threads</Typography>
//         </Breadcrumbs>
//         <Box className={classes.header}>
//           <Typography variant="h6">Threads List</Typography>
//           <Button variant="contained" onClick={() => openModal()}>Add Thread</Button>
//         </Box>
//         <ThreadsTable
//           threads={allThreads}
//           loading={loading}
//           onReadMore={handleReadMore}
//           onUpdateStatus={updateThreadStatus}
//           onEdit={handleEdit}
//         />
//         <ThreadDetailsModal
//           open={modalState.open}
//           onClose={closeModal}
//           onSubmit={handleAddEditThread}
//           toolData={modalState.tool}
//           isEditing={modalState.isEditing}
//         />
//         <Snackbar
//           open={snackbarState.open}
//           autoHideDuration={6000}
//           onClose={() => setSnackbarState(prev => ({ ...prev, open: false }))}
//           anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//         >
//           <Alert
//             onClose={() => setSnackbarState(prev => ({ ...prev, open: false }))}
//             severity={snackbarState.severity}
//           >
//             {snackbarState.message}
//           </Alert>
//         </Snackbar>
//       </Box>
//     </Box>
//   );
// };
// export default Threads;





import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  useMediaQuery,
  useTheme,
  Box,
  Button,
  Typography,
  Breadcrumbs,
  Snackbar,
  Alert,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Sidebar from './sidebar';
import ThreadDetailsModal from './ThreadDetailsModal';
import useStyles from './styles';
import makeApiRequest from '../utils/api';
import { getColumns } from './columns';
import DataTable from './commonTable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const Threads = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [threads, setThreads] = useState([]);
  const [localThreads, setLocalThreads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedThread, setSelectedThread] = useState(null);
  const [filteredThreads, setFilteredThreads] = useState([]);
  const [modalState, setModalState] = useState({
    open: false,
    tool: null,
    isEditing: false,
  });
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const threadsColumns = getColumns('threads');
  const threadsActions = [
    {
      label: 'Edit',
      icon: <EditIcon />,
      handler: (item) => handleEdit(item),
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/threads.json');
      setThreads(response.data.threads.slice(0, 5));
    } catch (error) {
      console.error('Error fetching threads:', error);
      showSnackbar('Failed to fetch threads', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getThreadById = async (threadId) => {
    setLoading(true);
    try {
      const localThread = localThreads.find((t) => t.id === threadId);
      if (localThread) {
        setSelectedThread(localThread);
      } else {
        const response = await axios.get(`/threads/${threadId}.json`);
        setSelectedThread(response.data);
        setFilteredThreads(response.data);
      }
      showSnackbar('Thread details fetched successfully');
      setModalState({ ...modalState, open: true });
    } catch (error) {
      console.error('Error fetching thread details:', error);
      showSnackbar('Failed to fetch thread details', 'error');
    } finally {
      setLoading(false);
    }
  };

  const createThread = async (threadData) => {
    try {
      // Mock API call
      await makeApiRequest('');
      const newThread = {
        ...threadData,
        id: Date.now().toString(),
        createdAt: new Date().toISOString(),
        createdBy: 'admin@agilophiles.com',
      };
      setLocalThreads((prevThreads) => [newThread, ...prevThreads]);
      showSnackbar('Thread created successfully');
      closeModal();
    } catch (error) {
      console.error('Error creating thread:', error);
      showSnackbar('Failed to create thread', 'error');
    }
  };

  const updateThreadStatus = async (threadId, newStatus) => {
    setLoading(true);
    try {
      const localThreadIndex = localThreads.findIndex((t) => t.id === threadId);
      if (localThreadIndex !== -1) {
        const updatedLocalThreads = [...localThreads];
        updatedLocalThreads[localThreadIndex] = {
          ...updatedLocalThreads[localThreadIndex],
          status: newStatus,
        };
        setLocalThreads(updatedLocalThreads);
      } else {
        await axios.put(`/threads/${threadId}.json`, { status: newStatus });
        const updatedThreads = threads.map((thread) =>
          thread.id === threadId ? { ...thread, status: newStatus } : thread
        );
        setThreads(updatedThreads);
      }
      showSnackbar('Thread status updated successfully');
    } catch (error) {
      console.error('Error updating thread status:', error);
      showSnackbar('Failed to update thread status', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleAddEditThread = (threadData) => {
    if (modalState.isEditing) {
      updateThreadStatus(threadData.id, threadData.status);
    } else {
      createThread(threadData);
    }
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarState({ open: true, message, severity });
  };

  const handleReadMore = async (threadId) => {
    await getThreadById(threadId);
  };

  const openModal = (tool = null) => {
    setModalState({
      open: true,
      tool: tool || { status: 'open' },
      isEditing: !!tool,
    });
  };

  const closeModal = () => {
    setModalState({
      open: false,
      tool: null,
      isEditing: false,
    });
  };

  const handleEdit = (threadId, currentStatus) => {
    setModalState({
      open: true,
      tool: { id: threadId, status: currentStatus },
      isEditing: true,
    });
  };

  const allThreads = [...localThreads, ...threads];

  const handleThreadsClick = () => navigate('/threads');

  return (
    <Box className={classes.root} sx={{ display: 'flex' }}>
      <Sidebar />
      <Box className={classes.content} sx={{flexGrow: 1,  paddingTop: "16px", paddingLeft:'5px' }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="/"
          sx={{ marginBottom: isMobile ? '0px' : '10px', marginTop: isMobile ? '20px' : '0px' }}
        >
          <Typography color="textPrimary">
            <HomeIcon fontSize="inherit" />
            Home
          </Typography>
          <Typography>Threads</Typography>
        </Breadcrumbs>
        {/* <Box className={classes.header}>
          <Typography variant="h6">Threads List</Typography>
          <Button variant="contained" onClick={() => openModal()}>
            Add Thread
          </Button>
        </Box> */}
        <DataTable
          item="Thread"
          openModal={openModal}
          handleClick={handleThreadsClick}
          data={allThreads}
          loading={loading}
          columns={threadsColumns}
          actions={threadsActions}
        />
        <ThreadDetailsModal
          open={modalState.open}
          onClose={closeModal}
          onSubmit={handleAddEditThread}
          toolData={modalState.tool}
          isEditing={modalState.isEditing}
        />
        <Snackbar
          open={snackbarState.open}
          autoHideDuration={6000}
          onClose={() => setSnackbarState((prev) => ({ ...prev, open: false }))}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setSnackbarState((prev) => ({ ...prev, open: false }))}
            severity={snackbarState.severity}
          >
            {snackbarState.message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default Threads;