import React, { useEffect, useState } from 'react';
import { useParams} from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import axios from 'axios';

const EditArticle = () => {
  const { articleId } = useParams();
  const [article, setArticle] = useState({
    name: '',
    description: '',
    steps: [{ position: 0, stepName: '', content: '' }],
  });
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    fetchArticle();
  }, []);

  const fetchArticle = async () => {
    try {
      const res = await axios.get(`http://agileflow-lb-1887497604.ap-south-1.elb.amazonaws.com/api/articles/${articleId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setArticle(res.data);
    } catch (err) {
      setError('Failed to fetch article');
      setSnackbarOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArticle({ ...article, [name]: value });
  };

  const handleStepChange = (index, field, value) => {
    const newSteps = [...article.steps];
    newSteps[index][field] = value;
    setArticle({ ...article, steps: newSteps });
  };

  const handleAddStep = () => {
    setArticle({
      ...article,
      steps: [...article.steps, { position: article.steps.length, stepName: '', content: '' }],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedArticle = {
        name: article.name,
        description: article.description,
        steps: article.steps.map((step, index) => ({
          position: index,
          stepName: step.stepName,
          content: step.content,
        })),
      };

      const res = await axios.put(`http://agileflow-lb-1887497604.ap-south-1.elb.amazonaws.com/api/articles/${articleId}`, updatedArticle, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      console.log(res)
    } catch (err) {
      setError('Failed to update article');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
      <Typography variant="h4" gutterBottom>Edit Article</Typography>
      <TextField
        label="Title"
        name="name"
        value={article.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Description"
        name="description"
        value={article.description}
        onChange={handleChange}
        multiline
        rows={4}
        fullWidth
        margin="normal"
        required
      />
      {article.steps.map((step, index) => (
        <Box key={index} sx={{ marginBottom: '20px' }}>
          <TextField
            label="Step Name"
            value={step.stepName}
            onChange={(e) => handleStepChange(index, 'stepName', e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Content"
            value={step.content}
            onChange={(e) => handleStepChange(index, 'content', e.target.value)}
            multiline
            rows={4}
            fullWidth
            margin="normal"
            required
          />
        </Box>
      ))}
      <Button
        variant="outlined"
        onClick={handleAddStep}
        sx={{ marginBottom: '20px' }}
      >
        Add Step
      </Button>
      <Button type="submit" variant="contained" color="primary">Update Article</Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">{error}</Alert>
      </Snackbar>
    </Box>
  );
};

export default EditArticle;

