import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import MarkDownPreview from './components/MarkDownPreview'
import Sidebar from './components/sidebar';
import Subjects from './components/subjects';
import Topics from './components/topics';
import Articles from './components/articles';
import EditArticle from './components/EditArticle';
import Threads from './components/Threads';
import Users from '././components/users'
import Tools from './components/Tools';

// const Layout = ({ children }) => (
//   <div style={{ display: 'flex' }}>
//       <Sidebar />
//       <div style={{ flex: 1 }}>{children}</div>
//   </div>
// );

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/sidebar" element={<Sidebar />} />
      <Route path="/markdown-preview/:topicId/:articleId?" element={<MarkDownPreview />} />
      <Route path="/users" element={<Users />} />
      <Route path="/subjects" element={<Subjects />} />
      <Route path="/topics/:subjectId" element={<Topics />} />
      <Route path="/articles/:topicId" element={<Articles />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route path="/edit-article/:articleId" element={<EditArticle />} />
      <Route path='/threads' element={<Threads />}/>
      <Route path='/tools' element={<Tools />}/>
    </Routes>
  );
};

export default App;


