import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: '2px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  tableContainer: {
    marginBottom: '5px',
    overflowX: 'auto',
  },
  tableHead: {
    padding: '2px',
    backgroundColor: theme.palette.custom.tableColor,
    '& .MuiTableCell-head': {
      color: 'white',
      // width: '30px',
      paddingTop: '10px',    
      height: '20px',     
      fontSize:'14px' 
    },
  },
  tableRow: {
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: '#F9F9F9',
    },
    '& td': {
      padding: '2px',
      paddingLeft: '10PX',
      fontSize: '15px',
    },
  },
  iconButton: {
    color: theme.palette.custom.tableColor,
  },
  skeletonRow: {
    '& td': {
      padding: '5px', 
      height: '16px',
      animation:'wave'
    },
  },
  asterisk: {
    color: 'red', 
  },
  DialogTitle: {
    backgroundColor: theme.palette.custom.tableColor,
    color: 'white',
    marginBottom: '20px'
  },
  CustomAsterisk: {
    color: 'red',
  },
  defaultSort: {
    color: 'white !important', 
    cursor: 'pointer',
    '&:hover': {
      color: 'gray !important',
    },
  },
  activeSort: {
    backgroundColor: 'dark blue !important',  
    color: 'gray !important', 
    '&:hover': {
      color: 'gray !important',
    },
    '& .MuiTableSortLabel-icon': {
      color: 'dark gray !important',
    },
  },

  name: {
    width: '15%',  
    // border: 'solid gray 2px'

  },

  description: {
    width: '40%', 
    // border: 'solid gray 2px'
 
  },

  createdBy: {
    width: '15%',
    // border: 'solid gray 2px'
    
  },
  createdAt: {
    width: '20%',
    paddingLeft:'50px',
    // border: 'solid gray 2px'
  },

  isActive:{
    width: '5%',
    // border: 'solid gray 2px',
    paddingLeft:'50px'
  },

  role:{
    width: '20%',
    // border: 'solid gray 2px'
  },

  email:{
    width: '25%',
    // border: 'solid gray 2px'
  },


  organization:{
    width: '30%',
    // paddingRight:'50px',
    // border: 'solid gray 2px'
  },

  status:{
    width: '50%',
    // paddingRight:'50px',
    // border: 'solid gray 2px'
  },

  // version:{
  //   paddingLeft:'20px'
  // },

  to:{
    margin:'0 5px'
  },

  senderMessage: {
    backgroundColor: '#333',  // Dark color for sender's message
    color: '#fff',            // White text for contrast
    padding: '10px',
    borderRadius: '10px',
    maxWidth: '80%',
    margin: '10px 0',
    alignSelf: 'flex-end',  
  },

  receiverMessage: {
    backgroundColor: '#f1f1f1',
    color: '#000',
    padding: '10px',
    borderRadius: '10px',
    maxWidth: '80%',
    margin: '10px 0',
    alignSelf: 'flex-start', 
  },


  // status:{
  //   width: '1%', 
  //   // border: 'solid gray 1px'
  // },


  filterInput: {
    padding: '2px', 
    width: '105px', 
    '& .MuiInputBase-input': {
        padding: '4px', 
        fontSize:'13px',
        color: 'white'
    }},
}));
export default useStyles;