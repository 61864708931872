import React from 'react';
import { Button, Box } from '@mui/material';

const Footer = ({ handleNextStep, handleBackStep, handleSave, hasErrors, step }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between', // Space between the left and right sides
                padding: 2,
                backgroundColor: '#FFFFFF'
            }} >
            {step > 1 && (
                <Button onClick={handleBackStep}>Back</Button>
            )}
            <Box sx={{ display: 'flex', gap: 1, marginLeft: 'auto' }}> {/* Ensure this box is aligned to the right */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNextStep}
                >
                    Next
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleSave}
                    disabled={hasErrors}
                >
                    Save
                </Button>
            </Box>
        </Box>
    );
};

export default Footer;