import React, { useEffect, useState } from 'react';
import { useTheme, Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Breadcrumbs, useMediaQuery, Snackbar, Alert } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Sidebar from './sidebar';
import Modal from './modal';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import { getColumns } from './columns';
import DataTable from './commonTable';
import makeApiRequest from '../utils/api';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import './subjects.css';

const Subjects = () => {

  const subjectsColumns = getColumns('subjects');

  const subjectsActions = [
    { label: 'Edit', icon: <EditIcon />, handler: (item) => handleEditSubject(item) },
    { label: 'Delete', icon: <DeleteIcon />, handler: (subjectId) => openDeleteConfirm(subjectId.id)},
  ];

  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [subjects, setSubjects] = useState([]);
  const [filteredSubjects, setFilteredSubjects] = useState([]);
  const [modalState, setModalState] = useState({ open: false, isEditing: false, subject: null });
  const [deleteConfirm, setDeleteConfirm] = useState({ open: false, subjectId: null });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ name: '', description: '' });
  
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleSubjectClick = (subject) => navigate(`/topics/${subject.id}`, { state: { subjectName: subject.name } });

  useEffect(() => {
    fetchSubjects();
  }, []);

  const fetchSubjects = async () => {
    setLoading(true);
    try {
      const data = await makeApiRequest(`api/subjects`);
      if (data && data.subjects) {
        const sortedSubjects = data.subjects.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setSubjects(sortedSubjects);
        setFilteredSubjects(sortedSubjects);
      } else {
        console.error('No subjects found in API response');
      }
    } catch (error) {
      console.error('Error fetching subjects:', error);
      showSnackbar('Error fetching subjects', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleAddOrEditSubject = async (subjectData) => {
    const url = modalState.isEditing && modalState.subject ? `api/subjects/${modalState.subject.id}` : 'api/subjects';
    const method = modalState.isEditing ? 'PUT' : 'POST';
    setLoading(true);
    try {
      await makeApiRequest(url, method, { 'Content-Type': 'application/json' }, subjectData);
      fetchSubjects();
      closeModal();
      showSnackbar(modalState.isEditing ? 'Subject updated successfully!' : 'Subject added successfully!', 'success');
    } catch (error) {
      console.error(`Error ${modalState.isEditing ? 'editing' : 'adding'} subject:`, error);
      showSnackbar(`Failed to ${modalState.isEditing ? 'edit' : 'add'} subject`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleEditSubject = async (subjectId) => {
    setLoading(true);
    try {
      const subjectData = await makeApiRequest(`api/subjects/${subjectId.id}`, 'GET');
      openModal(subjectData, true);
    } catch (error) {
      console.error('Error fetching subject data for editing:', error);
      showSnackbar('Error fetching subject data', 'error');
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteSubject = async () => {
    setLoading(true);
    try {
      await makeApiRequest(`api/subjects/${deleteConfirm.subjectId}`, 'DELETE');
      fetchSubjects();
      closeDeleteConfirm();
      showSnackbar('Subject deleted successfully!', 'success');
    } catch (error) {
      console.error('Error deleting subject:', error);
      showSnackbar('Failed to delete subject', 'error');
    } finally {
      setLoading(false);
    }
  };
  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const openModal = (subject = null, isEditing = false) => setModalState({ open: true, isEditing, subject });
  const closeModal = () => setModalState({ open: false, subject: null, isEditing: false });
  const openDeleteConfirm = (subjectId) => setDeleteConfirm({ open: true, subjectId });
  const closeDeleteConfirm = () => setDeleteConfirm({ open: false, subjectId: null });

  return (
    <Box className={classes.root} sx={{ display: "flex" }}>
      <Sidebar />
      <Box className={classes.content} sx={{ flexGrow: 1, paddingTop: "16px", paddingLeft:'5px'}}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="/"
          sx={{ marginTop: isMobile ? "20px" : "0px", marginBottom: isMobile ? "0px" : "10px" }}>
          <Typography className='breadcrumbs-title' color="textPrimary">
            <HomeIcon className='breadcrumbs-title margin-right-2' fontSize="inherit" />
            Home
          </Typography>
          <Typography>Subjects</Typography>
        </Breadcrumbs>
        <DataTable
          item = "Subject"
          openModal = {openModal}
          handleClick={handleSubjectClick}
          data={filteredSubjects}
          loading={loading}
          columns={subjectsColumns}
          actions={subjectsActions}
        />
        <Modal
          open={modalState.open}
          onClose={closeModal}
          onSubmit={handleAddOrEditSubject}
          data={modalState.subject}
          isEditing={modalState.isEditing}
        />
        <Dialog open={deleteConfirm.open} onClose={closeDeleteConfirm}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this subject?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDeleteConfirm}>Cancel</Button>
            <Button onClick={handleDeleteSubject}>Confirm</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};
export default Subjects;