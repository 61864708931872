// import React, { useState, useEffect } from 'react';
// import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TablePagination, Skeleton, Link, Tooltip, Switch, TextField, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
// import SortingComponent from './sort';
// import useStyles from './styles';
// import FilterComponent from './filter';
// import FullDescriptionModal from './FullDescriptionModal';
// import SearchIcon from '@mui/icons-material/Search';
// import './table.css'

// const DataTable = ({ data, loading, columns, actions, onToggleStatus, handleClick, openModal, item }) => {
//     const theme = useTheme();
//     const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//     const classes = useStyles();
//     const [filteredData, setFilteredData] = useState(data || []);
//     const [globalFilter, setGlobalFilter] = useState('');
//     const [columnFilters, setColumnFilters] = useState({});
//     const [selectedDescription, setSelectedDescription] = useState('');
//     const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
//     const [rowsPerPageState, setRowsPerPageState] = useState(data.length);

//     useEffect(() => {
//         setFilteredData(data);
//         setRowsPerPageState(data.length || 5);
//     }, [data]);

//     const applyFilters = (globalValue, columnFilters) => {
//         let filtered = data;

//         if (globalValue) {
//             const lowerCaseValue = globalValue.toLowerCase();
//             filtered = filtered.filter((row) =>
//                 columns.some((column) =>
//                     String(row[column.id]).toLowerCase().includes(lowerCaseValue)
//                 )
//             );
//         }

//         Object.keys(columnFilters).forEach((columnId) => {
//             const columnValue = columnFilters[columnId];

//             if (columnValue) {
//                 if (typeof columnValue === 'object' && columnValue.start && columnValue.end) {
//                     filtered = filtered.filter((row) => {
//                         const rowDate = new Date(row[columnId]);
//                         const startDate = new Date(columnValue.start);
//                         const endDate = new Date(columnValue.end);
//                         startDate.setHours(0, 0, 0, 0);
//                         endDate.setHours(23, 59, 59, 999);  
//                         rowDate.setHours(0, 0, 0, 0);
//                         return rowDate >= startDate && rowDate <= endDate;
//                     });
//                 }
                
//                 else {
//                     filtered = filtered.filter((row) =>
//                         String(row[columnId]).toLowerCase().includes(columnValue.toLowerCase())
//                     );
//                 }
//             }
//         });

//         setFilteredData(filtered);
//     };

//     const handleGlobalFilter = (value) => {
//         setGlobalFilter(value);
//         applyFilters(value, columnFilters);
//     };


//     const handleColumnFilter = (columnId, value) => {
//         const updatedColumnFilters = { ...columnFilters, [columnId]: value };
//         setColumnFilters(updatedColumnFilters);
//         applyFilters(globalFilter, updatedColumnFilters);
//     };

//     const handleReadMore = (event, description) => {
//         event.stopPropagation();
//         setSelectedDescription(description);
//         setDescriptionModalOpen(true);
//     };

//     const loadingRows = (rowsPerPage) => {
//         return Array.from({ length: rowsPerPage }, (_, i) => (
//             <TableRow key={i}>
//                 {columns.map((_, j) => (
//                     <TableCell key={j}>
//                         <Skeleton />
//                     </TableCell>
//                 ))}
//             </TableRow>
//         ));
//     };

//     const truncateText = (text, limit) => {
//         return text.length > limit ? `${text.substring(0, limit)}...` : text;
//     };

//     return (
//         <>
//             <Box sx={{
//                 marginTop: isMobile ? "40px" : "0px",
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 alignItems: 'center',
//                 marginBottom: '40px',
//                 marginTop: '15px'
//             }}>
//                 <Typography variant="h6">{item}s</Typography>
//                 <TextField
//                     className='search'
//                     variant="outlined"
//                     label="Search"
//                     value={globalFilter}
//                     onChange={(e) => handleGlobalFilter(e.target.value)}
//                     InputProps={{
//                         endAdornment: (
//                             <IconButton type="button" aria-label="search" size="small">
//                                 <SearchIcon />
//                             </IconButton>
//                         ),
//                     }}
//                 />
//                 <Button variant="contained" onClick={() => openModal()}>Add {item}</Button>
//             </Box>
//             <SortingComponent data={filteredData}>
//                 {({
//                     paginatedData,
//                     order,
//                     orderBy,
//                     handleSortRequest,
//                     page,
//                     rowsPerPageState,
//                     handleChangePage,
//                     handleChangeRowsPerPage
//                 }) => (
//                     <>
//                         <TableContainer component={Paper} className={classes.tableContainer}>
//                             <Table sx={{ minWidth: '100%' }}>
//                                 <TableHead className={classes.tableHead}>
//                                     <TableRow>
//                                         {columns.map((header) => (
//                                             <FilterComponent
//                                                 key={header.id}
//                                                 header={header}
//                                                 order={order}
//                                                 orderBy={orderBy}
//                                                 handleSortRequest={handleSortRequest}
//                                                 users={filteredData}
//                                                 setFilteredData={setFilteredData}
//                                                 className={classes[header.id]}
//                                                 onColumnFilter={handleColumnFilter}
//                                             />
//                                         ))}
//                                         <TableCell className={classes.actionsColumn}>Actions</TableCell>
//                                     </TableRow>
//                                 </TableHead>
//                                 <TableBody>
//                                     {loading ? (
//                                         loadingRows(rowsPerPageState)
//                                     ) : (
//                                         paginatedData.map((item) => (
//                                             <TableRow
//                                                 key={item.id}
//                                                 hover
//                                                 className={classes.tableRow}
//                                                 style={{ cursor: (item == 'Subject' || item === 'Topic') ? 'pointer' : 'default' }} 
//                                                 onClick={() => handleClick(item)} >
//                                                 {columns.map((column) => (
//                                                     <TableCell
//                                                     key={column.id}
//                                                     className={classes[column.id]}
//                                                     sx={{ padding: column.id === 'description' ? '4px' : '6px' }}>
//                                                     {column.id === 'description' ? (
//                                                         <>
//                                                             <span>{truncateText(item[column.id], 50)}</span>
//                                                             {item[column.id].length > 50 && (
//                                                                 <Link
//                                                                     component="button"
//                                                                     variant="body2"
//                                                                     onClick={(event) => handleReadMore(event, item[column.id])}>
//                                                                     Read More
//                                                                 </Link>
//                                                             )}
//                                                         </>
//                                                     ) : column.id === 'createdAt' ? (
//                                                         <TableCell style={{ borderBottom: 'none' }}>
//                                                             {new Date(item.createdAt).toLocaleDateString('en-GB')}
//                                                         </TableCell>
//                                                     ) : column.id === 'isActive' ? (
//                                                         <Box display="flex" alignItems="center">
//                                                             <Switch
//                                                                 checked={item.isActive}
//                                                                 onChange={() => onToggleStatus(item.email)}
//                                                                 color="primary"
//                                                                 inputProps={{ 'aria-label': 'Toggle Active/Inactive' }}
//                                                             />
//                                                         </Box>
//                                                     ) : (
//                                                         item[column.id]
//                                                     )}
//                                                 </TableCell>
//                                                 ))}
//                                                 <TableCell className={classes.id}>
//                                                     <Box display="flex" justifyContent="flex-end">
//                                                         {actions.map((action) => (
//                                                             <IconButton
//                                                                 key={action.label}
//                                                                 onClick={(event) => {
//                                                                     event.stopPropagation();
//                                                                     action.handler(item);
//                                                                 }} >
//                                                                 {action.icon}
//                                                             </IconButton>
//                                                         ))}
//                                                     </Box>
//                                                 </TableCell>
//                                             </TableRow>
//                                         )))}
//                                 </TableBody>
//                             </Table>
//                             <TablePagination
//                                 rowsPerPageOptions={[5, 10, 25]}
//                                 component="div"
//                                 count={filteredData.length}
//                                 rowsPerPage={rowsPerPageState}
//                                 page={page}
//                                 onPageChange={handleChangePage}
//                                 onRowsPerPageChange={handleChangeRowsPerPage}
//                             />
//                         </TableContainer>
//                         <FullDescriptionModal
//                             open={descriptionModalOpen}
//                             onClose={() => setDescriptionModalOpen(false)}
//                             description={selectedDescription}
//                         />
//                     </>
//                 )}
//             </SortingComponent>
//         </>
//     );
// };

// export default DataTable;






import React, { useState, useEffect } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    TablePagination,
    Skeleton,
    Link,
    Switch,
    TextField,
    Button,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import SortingComponent from './sort';
import useStyles from './styles';
import FilterComponent from './filter';
import FullDescriptionModal from './FullDescriptionModal';
import SearchIcon from '@mui/icons-material/Search';
import './table.css';

const DataTable = ({ data, loading, columns, actions, onToggleStatus, handleClick, openModal, item }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = useStyles();
    const [filteredData, setFilteredData] = useState(data || []);
    const [globalFilter, setGlobalFilter] = useState('');
    const [columnFilters, setColumnFilters] = useState({});
    const [selectedDescription, setSelectedDescription] = useState('');
    const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
    const [rowsPerPageState, setRowsPerPageState] = useState(data.length);

    useEffect(() => {
        setFilteredData(data);
        setRowsPerPageState(data.length || 5);
    }, [data]);

    const applyFilters = (globalValue, columnFilters) => {
        let filtered = data;

        if (globalValue) {
            const lowerCaseValue = globalValue.toLowerCase();
            filtered = filtered.filter((row) =>
                columns.some((column) =>
                    String(row[column.id]).toLowerCase().includes(lowerCaseValue)
                )
            );
        }

        Object.keys(columnFilters).forEach((columnId) => {
            const columnValue = columnFilters[columnId];

            if (columnValue) {
                if (typeof columnValue === 'object' && columnValue.start && columnValue.end) {
                    filtered = filtered.filter((row) => {
                        const rowDate = new Date(row[columnId]);
                        const startDate = new Date(columnValue.start);
                        const endDate = new Date(columnValue.end);
                        startDate.setHours(0, 0, 0, 0);
                        endDate.setHours(23, 59, 59, 999);
                        rowDate.setHours(0, 0, 0, 0);
                        return rowDate >= startDate && rowDate <= endDate;
                    });
                } else {
                    filtered = filtered.filter((row) =>
                        String(row[columnId]).toLowerCase().includes(columnValue.toLowerCase())
                    );
                }
            }
        });
        setFilteredData(filtered);
    };

    const handleGlobalFilter = (value) => {
        setGlobalFilter(value);
        applyFilters(value, columnFilters);
    };

    const handleColumnFilter = (columnId, value) => {
        const updatedColumnFilters = { ...columnFilters, [columnId]: value };
        setColumnFilters(updatedColumnFilters);
        applyFilters(globalFilter, updatedColumnFilters);
    };

    const handleReadMore = (event, description) => {
        event.stopPropagation();
        setSelectedDescription(description);
        setDescriptionModalOpen(true);
    };

    const loadingRows = (rowsPerPage) => {
        return Array.from({ length: rowsPerPage }, (_, i) => (
            <TableRow key={i}>
                {columns.map((_, j) => (
                    <TableCell key={j}>
                        <Skeleton />
                    </TableCell>
                ))}
            </TableRow>
        ));
    };

    const truncateText = (text, limit) => {
        return text.length > limit ? `${text.substring(0, limit)}...` : text;
    };

    const getActionsForItem = (item) => {
        if (item.status === 'PUBLISHED') {
            return actions.filter(action =>
                action.label === 'Publish' ||
                action.label === 'Create New' ||
                action.label === 'Delete'
            );
        } else if (item.status === 'DRAFTED') {
            return actions.filter(action =>
                action.label === 'Edit' ||
                action.label === 'Publish' ||
                action.label === 'Delete'
            );
        }
        return actions;
    };

    return (
        <>
            <Box sx={{
                marginTop: isMobile ? "40px" : "0px",
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '40px',
                marginTop: '15px'
            }}>
                <Typography variant="h6">{item}s</Typography>
                <TextField
                    className='search'
                    variant="outlined"
                    label="Search"
                    value={globalFilter}
                    onChange={(e) => handleGlobalFilter(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <IconButton type="button" aria-label="search" size="small">
                                <SearchIcon />
                            </IconButton>
                        ),
                    }}
                />
                <Button variant="contained" onClick={() => openModal()}>Add {item}</Button>
            </Box>
            <SortingComponent data={filteredData}>
                {({
                    paginatedData,
                    order,
                    orderBy,
                    handleSortRequest,
                    page,
                    rowsPerPageState,
                    handleChangePage,
                    handleChangeRowsPerPage
                }) => (
                    <>
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table sx={{ minWidth: '100%' }}>
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        {columns.map((header) => (
                                            <FilterComponent
                                                key={header.id}
                                                header={header}
                                                order={order}
                                                orderBy={orderBy}
                                                handleSortRequest={handleSortRequest}
                                                users={filteredData}
                                                setFilteredData={setFilteredData}
                                                className={classes[header.id]}
                                                onColumnFilter={handleColumnFilter}
                                                sx={{ width: header.width }} // Set width here
                                            />
                                        ))}
                                        <TableCell className={classes.actionsColumn}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loading ? (
                                        loadingRows(rowsPerPageState)
                                    ) : (
                                        paginatedData.map((item) => (
                                            <TableRow
                                                key={item.id}
                                                hover 
                                                className={classes.tableRow}
                                                style={{ cursor: (item === 'Subject' || item === 'Topic') ? 'pointer' : 'default' }}
                                                onClick={() => handleClick(item)}>
                                                {columns.map((column) => (
                                                 <TableCell
                                                 key={column.id}
                                                 className={classes[column.id]}
                                                 sx={{
                                                   padding: column.id === 'description' ? '4px' : '6px',
                                                   width: column.width, // Apply the specified width
                                                 }}
                                                >
                                                  {column.id === 'description' ? (
                                                    <>
                                                      <span>{truncateText(item[column.id], 50)}</span>
                                                      {item[column.id].length > 50 && (
                                                        <Link
                                                          component="button"
                                                          variant="body2"
                                                          onClick={(event) => handleReadMore(event, item[column.id])}
                                                        >
                                                          Read More
                                                        </Link>
                                                      )}
                                                    </>
                                                  ) : column.id === 'createdAt' ? (
                                                    <TableCell style={{ borderBottom: 'none',                                                    whiteSpace: column.id === 'createdAt' ? 'nowrap' : 'normal', // Adjust text wrapping if needed
                                                    }} >
                                                      {new Date(item.createdAt).toLocaleDateString('en-GB')}
                                                    </TableCell>
                                                  ) : column.id === 'isActive' ? (
                                                    <Box>
                                                      <Switch
                                                        checked={item.isActive}
                                                        onChange={() => onToggleStatus(item.email)}
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'Toggle Active/Inactive' }}
                                                      />
                                                    </Box>
                                                  ) : (
                                                    item[column.id]
                                                  )}
                                                </TableCell>                                                
                                                ))}
                                                <TableCell className={classes.id}>
                                                    <Box display="flex" justifyContent="flex-end">
                                                        {getActionsForItem(item).map((action) => (
                                                            <IconButton
                                                                key={action.label}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    action.handler(item);
                                                                }}>
                                                                {action.icon}
                                                            </IconButton>
                                                        ))}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filteredData.length}
                                rowsPerPage={rowsPerPageState}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                        <FullDescriptionModal
                            open={descriptionModalOpen}
                            onClose={() => setDescriptionModalOpen(false)}
                            description={selectedDescription}
                        />
                    </>
                )}
            </SortingComponent>
        </>
    );
};

export default DataTable;