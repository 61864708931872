import React, { useState, useEffect } from 'react';
import { useMediaQuery, useTheme, Box, Typography, Breadcrumbs, Snackbar, Alert } from '@mui/material';
import Sidebar from './sidebar';
import HomeIcon from '@mui/icons-material/Home';
import useStyles from './styles';
import UserModal from './userModal';
import makeApiRequest from '../utils/api';
import DataTable from './commonTable';
import EditIcon from '@mui/icons-material/Edit';
import { getColumns } from './columns';
import { useNavigate} from 'react-router-dom';

const Users = () => {
    const usersColumns = getColumns('users');
    const usersActions = [
        {
            label: 'Edit',
            icon: <EditIcon />,
            handler: (user) => setModalState({ open: true, user, isEditing: true }),
        },
    ];

    const navigate = useNavigate();
    const classes = useStyles();
    const theme = useTheme();
    const [users, setUsers] = useState([]);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalState, setModalState] = useState({ open: false, user: null, isEditing: false });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const data = await makeApiRequest('api/users', 'GET');
            if (data && Array.isArray(data.users)) {
                const sortedUsers = data.users.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                setUsers(sortedUsers);
                setFilteredUsers(sortedUsers);
            } else {
                console.error('No users found.');
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleUsersClick = () => navigate(`/users`);

    const handleAddEditUser = async (userData) => {
        setLoading(true);
        const method = modalState.isEditing ? 'PUT' : 'POST';
        const url = modalState.isEditing ? `api/users?email=${userData.email}` : 'api/users';

        try {
            await makeApiRequest(url, method, { 'Content-Type': 'application/json' }, userData);
            fetchUsers();
            closeModal();
            setSnackbarMsg(modalState.isEditing ? 'User updated successfully!' : 'User added successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            console.error(`Error ${modalState.isEditing ? 'editing' : 'adding'} user:`, error);
            setSnackbarMsg(error);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleToggleStatus = (email) => {
        const updatedUsers = users.map(user =>
            user.email === email ? { ...user, isActive: !user.isActive } : user
        );
        setUsers(updatedUsers);
    };

    const openModal = () => setModalState({ open: true, user: null, isEditing: false });
    const closeModal = () => setModalState({ open: false, user: null, isEditing: false });

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box className={classes.root} sx={{ display: 'flex' }}>
            <Sidebar />
            <Box className={classes.content} sx={{flexGrow: 1,  paddingTop: "16px", paddingLeft:'5px' }}>
                <Breadcrumbs 
                    aria-label="breadcrumb" 
                    separator="/" 
                    sx={{marginBottom: isMobile ? "0px" : "10px",marginTop : isMobile ? "20px" :"0px"}}
                    >
                    <Typography sx={{display:"flex",alignItems:"center"}} color="textPrimary">
                        <HomeIcon sx={{display:"flex",alignItems:"center",marginRight:"2px"}} fontSize="inherit" />Home
                    </Typography>
                    <Typography>Users</Typography>
                </Breadcrumbs>
                <DataTable
                    item = "User"
                    openModal={openModal}
                    handleClick={handleUsersClick}
                    data={filteredUsers}
                    loading={loading}
                    columns={usersColumns}
                    actions={usersActions}
                    onToggleStatus={handleToggleStatus}
                />
                <UserModal
                    open={modalState.open}
                    onClose={closeModal}
                    onSubmit={handleAddEditUser}
                    userData={modalState.user}
                    isEditing={modalState.isEditing}
                />
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                        {snackbarMsg}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

export default Users;