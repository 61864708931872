import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Link, IconButton, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from '../Logo.png';
import { useTheme } from '@mui/material';
import { Visibility, VisibilityOff, KeyboardCapslock } from '@mui/icons-material';
import makeApiRequest from '../utils/api';
import { validateField, validateForm } from '../utils/validationUtils';
import './login.css';

const Login = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [serverErrorMessage, setServerErrorMessage] = useState('');
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [isCapsLockOn, setIsCapsLockOn] = useState(false);

    const goToForgotPasswordPage = () => {
        navigate("/forgot-password");
    };

    const handleChange = (field) => (event) => {
        const newValue = event.target.value;
        setFormData(prevData => ({ ...prevData, [field]: newValue }));
        setErrors(prevErrors => ({ ...prevErrors, [field]: '' }));
        setServerErrorMessage('');
    };

    const handleBlur = (field) => () => {
        const error = validateField(field.toUpperCase(), formData[field]);
        setErrors(prevErrors => ({ ...prevErrors, [field]: error }));
    };

    const handleKeyDown = (event) => {
        if (event.getModifierState('CapsLock')) {
            setIsCapsLockOn(true);
        }
    };

    const handleKeyUp = (event) => {
        if (!event.getModifierState('CapsLock')) {
            setIsCapsLockOn(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { isValid, errors: formErrors } = validateForm(formData, ['EMAIL', 'PASSWORD']);
        if (!isValid) {
            setErrors(formErrors);
            return;
        }

        try {
            const response = await makeApiRequest("api/auth/login", "POST", {}, formData);
            if (response.token) {
                sessionStorage.setItem("token", response.token);
                setErrors({});
                setServerErrorMessage("");
                navigate("/subjects");
            } else {
                setServerErrorMessage("Unexpected error occurred, please contact the administrator");
            }
        } catch (error) {
            console.log(error);
            setServerErrorMessage(error || "An error occurred during login");
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Box
            className="flex-column-justify-center-align-center height-100vh"
            style={{ backgroundColor: theme.palette.primary.main }}
        >
            <Box className="flex-justify-center margin-bottom-1">
                <img src={Logo} alt="Logo" style={{ width: '100px', height: 'auto' }} />
            </Box>

            <Box className="form-container">
                <Typography variant="h4" gutterBottom align="center">
                    Admin UI
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.email}
                        error={!!errors.email}
                        onChange={handleChange('email')}
                        onBlur={handleBlur('email')}
                        helperText={errors.email}
                    />
                    <TextField
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.password}
                        error={!!errors.password}
                        onChange={handleChange('password')}
                        onBlur={handleBlur('password')}
                        onKeyDown={handleKeyDown}
                        onKeyUp={handleKeyUp}
                        helperText={errors.password}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isCapsLockOn && (
                                        <IconButton edge="end" disabled>
                                            <KeyboardCapslock style={{ color: 'red' }} />
                                        </IconButton>
                                    )}
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {serverErrorMessage && (
                        <Typography variant='h6' className='error-message'>
                            {serverErrorMessage}
                        </Typography>
                    )}
                    <Box className="flex-space-between-align-center margin-top-16">
                        <Link sx={{ cursor: "pointer" }} onClick={goToForgotPasswordPage} variant="body2">
                            Forgot Password?
                        </Link>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Login
                        </Button>
                    </Box>
                </form>
            </Box>
        </Box>
    );
};

export default Login;