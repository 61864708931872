import React, { useState, useEffect } from 'react';
import {useTheme, Box, TextField, Button, Typography, InputAdornment, IconButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff, KeyboardCapslock } from '@mui/icons-material';
import './reset-password.css';
import makeApiRequest from '../utils/api';


const ResetPassword = () => {
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [token, setToken] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isCapsLockOn, setIsCapsLockOn] = useState(false); 

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const token = urlParams.get("token");
        if (token) {
            setToken(token);
        } else {
            setError("No reset token found. Please try the password reset process again.");
        }
    }, [location]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newPassword) {
            setError("New password is required");
            return;
        }
        if (newPassword !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }
        try {
            const response = await makeApiRequest('api/auth/reset-password', 'POST', {}, { token, newPassword });
            console.log(response)
            setMessage("Password reset successful");
            setError("");
            setTimeout(() => {
                navigate("/");
            }, 2000);
        } catch (error) {
            setError(error);
        }
    };

    const handleKeyDown = (event) => {
        if (event.getModifierState('CapsLock')) {
            setIsCapsLockOn(true);
        }
    };

    const handleKeyUp = (event) => {
        if (!event.getModifierState('CapsLock')) {
            setIsCapsLockOn(false);
        }
    };

    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <Box
            style={{backgroundColor: theme.palette.primary.main}}
            className="reset-password-container"
        >
            <Box sx={{boxShadow:3}}
                className="reset-password-box-container"
            >
                <Typography variant="h4" gutterBottom align="center">
                    Reset Password
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="New Password"
                        variant="outlined"
                        type={showNewPassword ? 'text' : 'password'}
                        fullWidth
                        margin="normal"
                        value={newPassword}
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                            if (e.target.value === confirmPassword || confirmPassword === '') {
                                setError('');
                            }
                        }}
                        onKeyDown={handleKeyDown} 
                        onKeyUp={handleKeyUp} 
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isCapsLockOn && (
                                        <IconButton edge="end" disabled>
                                            <KeyboardCapslock style={{ color: 'red' }} />
                                        </IconButton>
                                    )}
                                    <IconButton onClick={handleClickShowNewPassword} edge="end">
                                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label="Confirm New Password"
                        variant="outlined"
                        type={showConfirmPassword ? 'text' : 'password'}
                        fullWidth
                        margin="normal"
                        value={confirmPassword}
                        onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            if (newPassword === e.target.value || newPassword === '') {
                                setError('');
                            }
                        }}
                        onKeyDown={handleKeyDown} 
                        onKeyUp={handleKeyUp} 
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isCapsLockOn && (
                                        <IconButton edge="end" disabled>
                                            <KeyboardCapslock style={{ color: 'red' }} />
                                        </IconButton>
                                    )}
                                    <IconButton onClick={handleClickShowConfirmPassword} edge="end">
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {message && (
                        <Typography variant='body1' className='success-message'>
                            {message}
                        </Typography>
                    )}
                    {error && (
                        <Typography variant='body1' className='error-message'>
                            {error}
                        </Typography>
                    )}
                    <Box className="button-container">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Reset Password
                        </Button>
                    </Box>
                </form>
            </Box>
        </Box>
    );
}

export default ResetPassword;
