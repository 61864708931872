import React, { useState, useEffect } from 'react';
import { Box, Snackbar, Alert, Breadcrumbs, Typography} from '@mui/material';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import Sidebar from './sidebar';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { validateField, VALIDATION_RULES } from '../utils/validationUtils';
import makeApiRequest from '../utils/api';
import HomeIcon from '@mui/icons-material/Home';
import './mark-down-preview.css';
import useStyles from './styles';

const MarkDownPreview = () => {

    const location = useLocation();
    const subjectName = location.state?.subjectName || 'Subject Name';
    console.log(subjectName, "subjectName");
    const topicName = location.state?.topicName || 'Topic Name';

    const classes = useStyles();
    const navigate = useNavigate();
    const { topicId, articleId } = useParams();
    const [step, setStep] = useState(1);
    const [articleName, setArticleName] = useState('');
    const [description, setDescription] = useState('');
    const [stepName, setStepName] = useState('Step 1');
    const [markdownContent, setMarkdownContent] = useState('');
    const [stepsContent, setStepsContent] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [articleNameError, setArticleNameError] = useState("");
    const [descriptionError, setDescriptionError] = useState('');
    const token = sessionStorage.getItem("token");

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (articleId) {
                    const articleResponse = await makeApiRequest(`api/articles/${articleId}`);
                    setArticleName(articleResponse.name);
                    setDescription(articleResponse.description);
                    setStepsContent(articleResponse.steps);
                    setStepName(articleResponse.steps[0]?.stepName || 'Step 1');
                    setMarkdownContent(articleResponse.steps[0]?.content || '');
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [articleId]);

    const handleNextStep = () => {
        const currentStepContent = { position: step, step_name: stepName, content: markdownContent };
        const updatedStepsContent = [...stepsContent];
        updatedStepsContent[step - 1] = currentStepContent;

        setStepsContent(updatedStepsContent);
        setStep(step + 1);
        setStepName(`Step ${step + 1}`);
        setMarkdownContent(updatedStepsContent[step]?.content || '');
    };

    const handleBackStep = () => {
        const currentStepContent = { position: step, step_name: stepName, content: markdownContent };
        const updatedStepsContent = [...stepsContent];
        updatedStepsContent[step - 1] = currentStepContent;
    
        setStepsContent(updatedStepsContent);
        setStep(step - 1);
        setStepName(`Step ${step - 1}`);
        setMarkdownContent(updatedStepsContent[step - 2]?.content || ''); // Adjust for 0-indexing
    };
    
    const handleArticleNameChange = (e) => {
        const newValue = e.target.value;
        setArticleName(newValue);
        const error = validateField('NAME', newValue);
        setArticleNameError(error);
    };

    const handleDescriptionChange = (e) => {
        const newValue = e.target.value;
        setDescription(newValue);
        const error = validateField('DESCRIPTION', newValue);
        setDescriptionError(error);
    };

    const handleArticleNameBlur = () => {
        const error = validateField('NAME', articleName);
        setArticleNameError(error);
    };

    const handleDescriptionBlur = () => {
        const error = validateField('DESCRIPTION', description);
        setDescriptionError(error);
    };

    const validateFields = () => {
        const articleNameError = validateField('NAME', articleName);
        const descriptionError = validateField('DESCRIPTION', description);
        
        setArticleNameError(articleNameError);
        setDescriptionError(descriptionError);
        
        return !articleNameError && !descriptionError;
    };

    const handleSave = async () => {
        const isValid = validateFields();
        if (!isValid) {
            return;
        }

        const currentStepContent = { position: step, step_name: stepName, content: markdownContent };
        const updatedStepsContent = [...stepsContent];
        updatedStepsContent[step - 1] = currentStepContent;

        const articleData = {
            name: articleName,
            description: description,
            topicId: topicId,
            steps: updatedStepsContent.map((content, index) => ({
                position: index + 1,
                stepName: content.step_name,
                content: content.content,
            })),
        };

        console.log(articleData);

        try {
            const apiUrl = articleId
                ? `/api/articles/${articleId}`
                : "/api/articles";

            const method = articleId ? 'PUT' : 'POST';

            await makeApiRequest(apiUrl, method, {}, articleData);

            setSnackbarMessage('Article saved successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);

            setTimeout(() => {
                navigate(`/articles/${topicId}`,
                    {
                        state: {
                            subjectName: subjectName,
                            topicName: topicName
                        }
                    }
                );
            }, 1000);
        } catch (error) {
            console.error("Error saving article:", error);
            setSnackbarMessage('Error saving article. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const hasErrors = !!(articleNameError || descriptionError);

    const handleBackToSubjects = () => {
        navigate(-3);
    };

    const handleBackToTopics = () => {
        navigate(-2);
    };

    const handleBackToArticles = () => {
        navigate(-1);
    };

    return (
        <Box className={classes.root} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
            <Sidebar />
            <Box
                sx={{
                    width: { xs: '100%', md: '80%' },
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                    backgroundColor: '#ffffff',
                }} >
                <Breadcrumbs separator="/"
                    aria-label="breadcrumb">
                    <Typography color="textPrimary" onClick={() => navigate('/')}>
                        <HomeIcon fontSize="inherit" />Home
                    </Typography>
                    <Typography
                        color="textPrimary"
                        onClick={handleBackToSubjects}
                        sx={{ cursor: 'pointer' }}>
                        Subjects - {subjectName.length > 10 ? `${subjectName.slice(0, 10)}...` : subjectName}
                    </Typography>
                    <Typography
                        color="textPrimary"
                        onClick={handleBackToTopics}
                        sx={{ cursor: 'pointer' }}>
                        Topics - {topicName.length > 10 ? `${topicName.slice(0, 10)}...` : topicName}
                    </Typography>
                    <Typography onClick={handleBackToArticles}>Articles</Typography>
                    <Typography> New Article</Typography>
                </Breadcrumbs>
                <Header
                    description={description}
                    setDescription={handleDescriptionChange}
                    articleName={articleName}
                    setArticleName={handleArticleNameChange}
                    stepName={stepName}
                    setStepName={setStepName}
                    step={step}
                    articleNameError={articleNameError}
                    descriptionError={descriptionError}
                    handleArticleNameBlur={handleArticleNameBlur}
                    handleDescriptionBlur={handleDescriptionBlur}
                />
                <Body
                    markdownContent={markdownContent}
                    setMarkdownContent={setMarkdownContent}
                />
                <Footer
                    step={step}
                    handleNextStep={handleNextStep}
                    handleSave={handleSave}
                    hasErrors={hasErrors}
                    handleBackStep={handleBackStep}
                />
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default MarkDownPreview;