
import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: "#E3F2FD",
        },
        secondary: {
            main: "#131723",
        },
        custom: {
            tableColor: '#1565C0',
            disabledColor: '#ccc',
        },
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#1976d2',
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1565C0',
                    color: '#ffffff',
                    '&:hover': {
                        backgroundColor: '#115293',
                    }
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#E2E2E2',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#1976d2',
                        },
                    }
                },
                label: {
                    color: '#1976d2',
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-focused': {
                        color: '#1976d2',
                    },
                    '&.MuiFormLabel-filled': {
                        color: '#1976d2',
                    },
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    '&.deleteButton': {
                        color: 'red',
                    },
                },
            },
        },
    },
    MuiTableHead: {
        styleOverrides: {
            root: {
                backgroundColor: 'black', 
                fontWeight: 'bold'
            },
        },
    },
    MuiTableCell: {
        styleOverrides: {
            head: {
                paddingTop: '4px', 
            },
        },
    },

    MuiTab: {
        styleOverrides: {
          root: {
            color: 'pink',
            '&.Mui-selected': {
              color: 'red',
              fontWeight: 'bold', 

              
            },
          },
        },
      },
    typography: {
        h1: {
            fontSize: "2rem",
            fontWeight: "600",
        },
        h2: {

        },
        h3: {
            fontSize: "1.5rem",
            fontWeight: "600",
            lineHeight: 1.2,
        },
        h4: {

        },
        h5: {

        },
        h6: {

        },
        p: {

        },
        span: {

        }
    },
});

export default theme;