import React from 'react';
import Grid from '@mui/material/Grid2'
import { TextField } from '@mui/material';

const Header = ({
    description,
    setDescription,
    articleName,
    setArticleName,
    stepName,
    setStepName,
    step,
    articleNameError,
    descriptionError,
    handleArticleNameBlur,
    handleDescriptionBlur
}) => {
    return (
        <Grid container spacing={2} sx={{ backgroundColor: '#FFFFFF', padding: 2 }}>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Article Name"
                    variant="outlined"
                    fullWidth
                    value={articleName}
                    onChange={setArticleName}
                    onBlur={handleArticleNameBlur}
                    error={!!articleNameError}
                    helperText={articleNameError}
                    disabled={step > 1}
                    InputProps={{
                        sx: { backgroundColor: '#E6E0E9' }
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Step Name"
                    variant="outlined"
                    fullWidth
                    value={stepName}
                    onChange={(e) => setStepName(e.target.value)}
                    InputProps={{
                        sx: { backgroundColor: '#E6E0E9' }
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Description"
                    variant="outlined"
                    fullWidth
                    value={description}
                    onChange={setDescription}
                    onBlur={handleDescriptionBlur}
                    error={!!descriptionError}
                    helperText={descriptionError}
                    InputProps={{
                        sx: { backgroundColor: '#E6E0E9' }
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default Header;