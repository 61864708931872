export const getColumns = (tableName) => {
  switch (tableName) {
    case 'subjects':
    case 'topics':
      return [
        { id: 'name', label: 'Title' },
        { id: 'description', label: 'Description' },
        { id: 'createdBy', label: 'Created By' },
        { id: 'createdAt', label: 'Created On', type: 'date'},
      ];

    case 'articles':
      return [
        { id: 'name', label: 'Title', className: 'nameColumn' },
        { id: 'description', label: 'Description', className: 'descriptionColumn' },
        { id: 'createdBy', label: 'Created By', className: 'createdByColumn' },
        { id: 'createdAt', label: 'Created On', className: 'createdOnColumn',  type: 'date', width: '30%' },
        { id: 'version', label: 'Version', className: 'versionColumn'},
        { id: 'status', label: 'Status', className: 'statusColumn' },
      ];

    case 'users':
      return [
        { id: 'name', label: 'Name' },
        { id: 'role', label: 'Role' },
        { id: 'organization', label: 'Organization' },
        { id: 'email', label: 'Email' },
        { id: 'isActive', label: 'Status', sortable: false },
      ];

    case 'tools':
      return [
        { id: 'name', label: 'Title' },
        { id: 'description', label: 'Description' },
        { id: 'url', label: 'URL', width: '12%' },
        { id: 'createdBy', label: 'Created By',width: '10%' },
        { id: 'createdAt', label: 'Created On', type: 'date'},
      ];

    case 'threads':
      return [
        { id: 'title', label: 'Title', width: '35%' },
        { id: 'createdBy', label: 'Created By', width: '25%' },
        { id: 'createdAt', label: 'Created At', type: 'date', width: '25%'},
        { id: 'status', label: 'Status'},
      ];

    default:
      return [];
  }
};