import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Alert, Breadcrumbs, CircularProgress, Tooltip } from '@mui/material';
import Sidebar from './sidebar';
import makeApiRequest from '../utils/api';
import './articles.css';
import HomeIcon from '@mui/icons-material/Home';
import { getColumns } from './columns';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import DataTable from './commonTable';
import useStyles from './styles';

const Articles = () => {
  const classes = useStyles();
  const { topicId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const subjectName = location.state?.subjectName || 'Subject Name';
  const topicName = location.state?.topicName || 'Topic Name';

  const articleColumns = getColumns('articles');

  const articleActions = [
    { label: 'Edit', icon: <EditIcon />, handler: (item) => handleEditClick(item) },
    { label: 'Create New', icon: <AddBoxIcon fontSize="small" backgroundColor="primary" />, handler: (item) => handleCreateNewVersion(item) },
    { label: 'Delete', icon: <DeleteIcon />, handler: (item) => handleDeleteArticle(item.id) },
    { label: 'Publish', icon: <Button size="small">Publish</Button>, handler: (item) => publishArticle(item.id) },
  ];

  useEffect(() => {
    fetchData();
  }, [topicId]);

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await makeApiRequest(`api/articles/topic/${topicId}`);
      const articlesWithVersion = response.map(article => ({
        ...article,
        version: article.version ? parseInt(article.version, 10) : 1,
        status: article.status || "DRAFTED"
      }));
      setArticles(getLatestArticlesByVersion(articlesWithVersion));
    } catch (error) {
      console.error('Error fetching articles:', error);
      setArticles([]);
    } finally {
      setLoading(false);
    }
  };

  const getLatestArticlesByVersion = (articleList) => {
    const groupedArticles = articleList.reduce((acc, article) => {
      if (!acc[article.key] || article.version > acc[article.key].version) {
        acc[article.key] = article;
      }
      return acc;
    }, {});
    return Object.values(groupedArticles);
  };

  const handleCreateNewVersion = async (article) => {
    setLoading(true);
    try {
      const response = await makeApiRequest(`api/articles/${article.id}/version`, 'POST');
      if (response && response.version) {
        await fetchData();
        showSnackbar('New draft version created successfully');
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Failed to create new version:', error);
      showSnackbar('Failed to create new version', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = async (article) => {
    navigate(`/markdown-preview/${topicId}/${article.id}`, {
      state: {
        subjectName,
        topicName,
        currentVersion: article.version,
        isEditing: true,
        articleData: {
          title: article.title,
          content: article.content,
          status: article.status
        }
      }
    });
    showSnackbar('Editing article...', 'info');
  };

  const handleDeleteArticle = (id) => {
    setArticleToDelete(id);
    setOpenConfirmDialog(true);
  };

  const confirmDeleteArticle = async () => {
    setLoading(true);
    try {
      await makeApiRequest(`api/articles/${articleToDelete}`, 'DELETE');
      fetchData();
      showSnackbar('Article deleted successfully');
    } catch (error) {
      console.error('Failed to delete article:', error);
      showSnackbar('Failed to delete article', 'error');
    } finally {
      setOpenConfirmDialog(false);
      setLoading(false);
    }
  };

  const publishArticle = async (id) => {
    setLoading(true);
    try {
      const article = articles.find(article => article.id === id);
      if (article && article.status === "PUBLISHED") {
        showSnackbar('Article is already published');
        return;
      }
      const response = await makeApiRequest(`api/articles/${id}/publish`, 'POST');
      if (response && response.version) {
        await fetchData();
        showSnackbar('Article published successfully');
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Failed to publish article:', error);
      showSnackbar('Failed to publish article', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleArticleClick = () => {
    return;
  }
  const handleBackToSubjects = () => {
    navigate(-2);
  };

  const handleBackToTopics = () => {
    navigate(-1);
  };

  return (
    <Box className="article-main-container">
      <Sidebar />
      <Box className={classes.content} sx={{ flexGrow: 1, paddingTop: "16px", paddingLeft:'5px'}}>
        <Breadcrumbs separator="/" aria-label="breadcrumb">
          <Typography color="textPrimary" onClick={() => navigate('/')}>
            <HomeIcon fontSize="inherit" />Home
          </Typography>
          <Tooltip title={subjectName} arrow>
            <Typography color="textPrimary" onClick={handleBackToSubjects} sx={{ cursor: 'pointer' }}>
              Subjects - {subjectName}
            </Typography>
          </Tooltip>
          <Tooltip title={topicName} arrow>
            <Typography color="textPrimary" onClick={handleBackToTopics} sx={{ cursor: 'pointer' }}>
              Topics - {topicName}
            </Typography>
          </Tooltip>
          <Typography>Articles</Typography>
        </Breadcrumbs>
        <DataTable
          item="Article"
          handleClick={handleArticleClick}
          openModal={() => navigate(`/markdown-preview/${topicId}`, { state: { subjectName, topicName } })}
          data={articles}
          loading={loading}
          columns={articleColumns}
          actions={articleActions}
        />

        <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this article?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirmDialog(false)}>Cancel</Button>
            <Button onClick={confirmDeleteArticle} color="error" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Delete'}
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}>
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default Articles;