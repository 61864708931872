import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography, useTheme, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './forgot-password.css';
import makeApiRequest from '../utils/api';
import { validateField } from '../utils/validationUtils';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [message, setMessage] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [countDown, setCountDown] = useState(0)


    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        setEmailError("");
        setError("");
    }

    const handleEmailBlur = (e) => {
        const error = validateField('EMAIL', email);
        setEmailError(error);
    }

    useEffect(() => {
        let timer;
        if (isButtonDisabled && countDown > 0) {
            timer = setInterval(() => {
                setCountDown(prevCount => prevCount - 1);
            }, 1000);
        } else if (countDown === 0) {
            setIsButtonDisabled(false);
        }

        return () => clearInterval(timer);
    }, [isButtonDisabled, countDown])


    const handleSubmit = async (e) => {
        e.preventDefault();
        const emailValidationError = validateField('EMAIL', email)
        if (emailValidationError) {
            setEmailError(emailValidationError);
            return;
        }
        setIsButtonDisabled(true);
        setCountDown(30)
        try {
            const response = await makeApiRequest("api/auth/forgot-password", "POST", {}, { email });
            console.log(response)
            setMessage("Password reset link sent to your email");
            setEmail("")
        } catch (error) {
            setError("Error sending reset link, please try again.");
            setIsButtonDisabled(false);
            setCountDown(0);
        }

    }

    const goToLoginPage = () => {
        navigate("/")
    }
    return (
        <Box style={{ backgroundColor: theme.palette.primary.main }}
            className="forgot-password-container"
        >
            <Box
                className="form-container"
            >
                <Typography variant="h4" gutterBottom align="center">
                    Forgot Password
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={handleEmailChange}
                        onBlur={handleEmailBlur}
                        error={!!emailError}
                        helperText={emailError}
                    />
                    {message && (
                        <Typography variant='h6' className="success-message">
                            {message}
                        </Typography>
                    )}
                    {error && (
                        <Typography variant='h6' className="error-message">
                            {error}
                        </Typography>
                    )}
                    <Box className="button-container" >
                        <Link onClick={goToLoginPage} variant="body2" className="back-to-login">
                            Back to Login
                        </Link>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isButtonDisabled}
                        >
                            {isButtonDisabled ? `${countDown}` : "Send Reset Link"}
                        </Button>
                    </Box>
                </form>
            </Box>
        </Box>
    )
}

export default ForgotPassword
